import Link from "next/link";
import React from "react";

type AnchorElementProps = Omit<React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, "href" | "as" | "children">;

interface AnchorProps extends AnchorElementProps {
  href: string;
  as?: string;
  children?: React.ReactNode;
}

export default function Anchor({ href, as, children, ...rest }: AnchorProps) {
  return (
    // https://nextjs.org/docs/api-reference/next/link
    <Link href={href} as={as}>
      <a {...rest}>{children}</a>
    </Link>
  );
}
