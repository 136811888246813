import { PlusIcon } from "@heroicons/react/outline";
import Button from "@library/Button";
import { notification } from "antd";
import Anchor from "components/atoms/Anchor";
import ProjectCard, { SkeletonProjectCard } from "components/molecules/ProjectCard";
import PageHeader from "components/templates/PageHeader";
import PageLayout from "components/templates/PageLayout";
import { useRequireAuth } from "lib/use-auth";
import { debounce } from "lodash";
import { Fragment, useState } from "react";
import { InView } from "react-intersection-observer";
import { useProjects } from "services/projects.service";

interface ProjectListItemsProps {
  page: number;
  projectFilter: string;
  loadedPageCount: number;
  handleObserverChange: (inView: boolean) => void;
  sortBy?: string;
}

const ProjectListItems = ({ page, projectFilter, loadedPageCount, handleObserverChange, sortBy = "created_at" }: ProjectListItemsProps): JSX.Element => {
  const pageSize = 10;

  const {
    records: projects,
    total: totalProjectCount,
    loading: projectsLoading,
    deleteById,
    cloneById,
  } = useProjects({
    filter: projectFilter,
    page: page,
    size: pageSize,
    sortBy,
    order: "desc",
  });

  const totalPages = Math.ceil(totalProjectCount / pageSize);

  const deleteProject = async (id: number) => await deleteById({ projectId: id });
  const cloneProject = async (id: number) => await cloneById({ projectId: id });

  return (
    <div className="grid grid-cols-12 ">
      <div className="col-span-12 lg:col-span-8 lg:col-start-3">
        {projectsLoading ? (
          <div className="flex flex-col items-center justify-center">
            <SkeletonProjectCard />
          </div>
        ) : (
          <div className="">
            {projects?.length > 0 && (
              <div className="flex flex-col items-center justify-center">
                {projects.map((project) => {
                  return (
                    <Fragment key={project.id}>
                      <ProjectCard key={project.id} project={project} deleteProject={deleteProject} cloneProject={cloneProject} />
                    </Fragment>
                  );
                })}
              </div>
            )}
            {page == loadedPageCount - 1 && loadedPageCount < totalPages && (
              <InView onChange={handleObserverChange}>
                <SkeletonProjectCard />
              </InView>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const WelcomeMessage = (): JSX.Element => (
  <div className="flex flex-col items-center justify-center">
    <div className=" overflow-hidden rounded-lg border border-gray-300 bg-white px-20 shadow-d ">
      <div className="px-16 py-32">
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-48  w-64 flex-shrink-0 items-center justify-center text-4xl sm:mx-0 sm:h-48 sm:w-64">
            <img className="h-64 w-auto" src="/img/logos/SVG/Humaloop_Logo_Symbol_Black.svg" alt="Humanloop logo" />
          </div>
          <div className="mt-16 text-center sm:mt-0 sm:ml-16 sm:text-left">
            <h3 className="font-medium text-xl leading-6 text-gray-900" id="modal-headline">
              Welcome to Humanloop
            </h3>
            <div className="mt-8">
              <p className="text-base leading-6 text-gray-600">Create a project to start creating your machine learning model.</p>
            </div>
          </div>
        </div>
        <div className="mt-20 sm:mt-24 sm:ml-64 sm:flex sm:pl-16">
          <Anchor href="/projects/new" className="rounded shadow-sm">
            <Button styling="solid" size={40}>
              Create a project
            </Button>
          </Anchor>
        </div>
      </div>
    </div>
  </div>
);

export const ProjectPage = (): JSX.Element => {
  const { total: totalProjectCount } = useProjects({ filter: "" });

  const [loadedPageCount, setLoadedPageCount] = useState(1);
  const [projectFilter, setProjectFilter] = useState("");
  const { user, loading } = useRequireAuth("/login");
  const MAX_PROJECTS = user?.tier?.limits["projects"] || 6;
  const [sortBy, setSortBy] = useState<string>("created_at");

  const openNotification = () => {
    notification.warn({
      message: "Maximum number of projects",
      description: `You're currently limited to ${MAX_PROJECTS} active projects at any time. To upgrade your account, message us on chat.`,
    });
  };

  const handleFilterChange = debounce((filterValue) => {
    setProjectFilter(filterValue);
  }, 300);

  const handleObserverChange = (inView: boolean) => {
    if (inView) {
      setLoadedPageCount(loadedPageCount + 1);
    }
  };

  const pages = [];

  for (let i = 0; i < loadedPageCount; i++) {
    pages.push(
      <ProjectListItems
        key={i}
        page={i}
        handleObserverChange={handleObserverChange}
        loadedPageCount={loadedPageCount}
        projectFilter={projectFilter}
        sortBy={sortBy}
      />
    );
  }

  return (
    <PageLayout
      pageName="Projects"
      header={
        <PageHeader
          title="Projects"
          actions={
            <>
              <Anchor href="/projects/new">
                <Button
                  onClick={(e) => {
                    if (totalProjectCount >= MAX_PROJECTS) {
                      openNotification();
                      e.preventDefault();
                    }
                  }}
                  styling="solid"
                  IconLeft={PlusIcon}
                >
                  New project
                </Button>
              </Anchor>
            </>
          }
          searchProps={{ placeholder: "Search projects...", onChange: handleFilterChange }}
        />
      }
    >
      {totalProjectCount === 0 && <WelcomeMessage />}
      {pages}
    </PageLayout>
  );
};

export default ProjectPage;
