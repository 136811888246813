import React from "react";
import { Switch } from "@headlessui/react";

interface Props {
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
  label?: any;
  size?: "sm" | "md" | "lg";
  className?: string;
}

export const Toggle = (props: Props): JSX.Element => {
  let wrapperSize, translation, handleSize, fontSize;
  const size = props.size || "md";
  switch (size) {
    case "sm":
      wrapperSize = "h-12 w-24";
      fontSize = "text-11-14";
      translation = "translate-x-12";
      handleSize = "h-8 w-8";
      break;
    case "lg":
      fontSize = "text-base";
      wrapperSize = "h-28 w-56";
      translation = "translate-x-28";
      handleSize = "h-24 w-24";
      break;
    default:
    case "md":
      wrapperSize = "h-16 w-32";
      fontSize = "text-12-16";
      translation = "translate-x-16";
      handleSize = "h-12 w-12";
      break;
  }

  return (
    <Switch.Group as="div" className={`flex items-center ${props.className || ""}`}>
      <Switch
        checked={props.enabled}
        onChange={props.setEnabled}
        className={`hl-toggle ${props.enabled ? "bg-blue-600" : "bg-gray-400"}
          relative inline-flex flex-shrink-0 shadow-inner ${wrapperSize} cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={`${props.enabled ? translation : "translate-x-0"}
            pointer-events-none inline-flex items-center justify-center ${handleSize} transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
        ></span>
      </Switch>
      {props.label && (
        <Switch.Label as="span" className={`ml-12 ${fontSize} cursor-pointer select-none `}>
          {props.label}
        </Switch.Label>
      )}
    </Switch.Group>
  );
};
