import React from "react";
import { stringify } from "lib/utils";
import { useDebug } from "context/debug-context";
import { Toggle } from "./Toggle/Toggle";
import { BeakerIcon } from "@heroicons/react/solid";

// Pretty print a variable to screen
const Debug = ({ vars, className = "", force = false }) => {
  const [debug, setDebug] = useDebug();
  if (!force) {
    if (process.env.NODE_ENV !== "development") {
      return null;
    }

    if (!debug) {
      return null;
    }
  }
  const count = Object.keys(vars).length;
  const width = ["w-full", "w-4/2", "w-4/3", "w-4/4", "w-4/5"][Math.min(count, 5) - 1];

  return (
    <div className={"hl-debug flex flex-wrap " + className}>
      {Object.entries(vars).map(([name, val], i) => (
        <div key={name} className={`${width} max-h-128 max-w-xl overflow-scroll border p-2 `}>
          <div className="flex justify-between ">
            <h4 className="text-sm font-bold">{name}</h4>
          </div>
          <pre className="h-full overflow-scroll text-xs">{val && stringify(val)}</pre>
        </div>
      ))}
    </div>
  );
};
export default Debug;

export function DebugSwitch() {
  const [debug, setDebug] = useDebug();
  if (process.env.NODE_ENV !== "development") {
    return null;
  }

  return (
    <div className="hl-debug-toggle mr-16">
      <Toggle label={<BeakerIcon className="w-20 text-white" />} enabled={debug} setEnabled={setDebug} />
    </div>
  );
}
