import React from "react";

const SpanTagging = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.8 10.952L2.379 13.376C1.81628 13.9386 1.50014 14.7018 1.50014 15.4975C1.50014 16.2932 1.81628 17.0564 2.379 17.619L3.568 18.808C3.84928 19.0896 4.23096 19.2479 4.629 19.248H7.886C8.28403 19.2479 8.66572 19.0896 8.947 18.808L10.8 16.951M6 22.248H22.5M12.5675 16.6873L19.4463 9.80852C20.032 9.22273 20.032 8.27298 19.4463 7.6872L14.0673 2.30824C13.4815 1.72245 12.5318 1.72245 11.946 2.30824L5.06724 9.18697C4.48146 9.77276 4.48146 10.7225 5.06724 11.3083L10.4462 16.6873C11.032 17.273 11.9817 17.273 12.5675 16.6873Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SpanTagging;
