import { InformationCircleIcon } from "@heroicons/react/outline";
import Tippy from "@tippyjs/react";
import { Placement } from "tippy.js";
import React from "react";

interface InfoTooltipProps {
  text: string;
  placement?: Placement;
}

export const InfoTooltip = ({ text, ...props }: InfoTooltipProps) => {
  // Information tooltip - use for adding additional helpful information
  return (
    <Tippy content={text} {...props}>
      <span>
        <InformationCircleIcon className="mb-2 inline-block h-16 w-16 text-gray-600 hover:text-gray-800" />
      </span>
    </Tippy>
  );
};
