import { DotsVerticalIcon, ExclamationCircleIcon } from "@heroicons/react/outline";
// TODO: avoid antd - we have our own DropDown now
import Debug from "@components/atoms/Debug";
import { StatDisplay } from "@components/organisms/ProjectOverview/StatDisplay";
import IconButton from "@library/IconButton";
import { Dropdown, Menu, Modal, notification, Skeleton } from "antd";
import Anchor from "components/atoms/Anchor";
import SpanTagging from "components/atoms/icons/SpanTagging";
import { useRouter } from "next/router";
import React from "react";
import { Project, TaskTypes } from "types/project";
import Avatar from "../atoms/Avatar";
import Category from "../atoms/icons/Category";

const { confirm } = Modal;

interface ProjectCardProps {
  project: Project;
  deleteProject: (projectId: number) => void;
  cloneProject: (projectId: number) => void;
}

const ProjectCard = ({ project, deleteProject, cloneProject }: ProjectCardProps) => {
  const router = useRouter();
  function showDeleteConfirm() {
    confirm({
      title: "Are you sure you want to delete this project?",
      icon: <ExclamationCircleIcon className="anticon h-24 w-24 text-red-600" />,
      content: "This will delete your model and and labeled data associated with it",
      onOk: async () => {
        await deleteProject(project.id);
        notification.success({
          message: `Project deleted`,
          description: (
            <>
              Project <strong>{project.name}</strong> deleted successfully.
            </>
          ),
          placement: "bottomRight",
        });
      },
    });
  }
  // feels like this confirm dialogue should be a separate parameterised component,
  // but just copying whats there for now and we may want different behaviour
  function showCloneConfirm() {
    confirm({
      title: "Are you sure you want to clone this project?",
      icon: <ExclamationCircleIcon className="anticon h-24 w-24 text-red-600" />,
      content: "This will clone your project and the labelled data associated with it and initialise a new model",
      onOk: async () => {
        await cloneProject(project.id);
        notification.success({
          message: `Project cloned`,
          description: (
            <>
              Project <strong>{project.name}</strong> cloned successfully.
            </>
          ),
          placement: "bottomRight",
        });
      },
    });
  }

  function menuClick({ domEvent, key }) {
    domEvent.preventDefault();
    switch (key) {
      case "train":
        router.push("/projects/[projectId]/tasks/[taskId]", `/projects/${project.id}/tasks/train`);
        break;
      case "model":
        router.push("/projects/[projectId]", `/projects/${project.id}`);
        break;
      case "tasks":
        router.push("/projects/[projectId]/#data", `/projects/${project.id}/#data`);
        break;
    }
  }

  const menu = (
    <Menu onClick={menuClick}>
      <Menu.Item key="train">Train/Annotate</Menu.Item>
      <Menu.Item key="model">Explore model</Menu.Item>
      <Menu.Item key="tasks">Explore tasks</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="delete" onClick={showDeleteConfirm}>
        Delete
      </Menu.Item>
      <Menu.Item key="clone" onClick={showCloneConfirm}>
        Clone
      </Menu.Item>
    </Menu>
  );

  const latestEvaluation = project.learner?.latest_evaluation;
  const accuracy = latestEvaluation?.eval_metrics?.test_score;
  const params = (latestEvaluation?.eval_metrics as any)?.trainer_params; //TODO: fix type here.
  // In the project response's learner, eval_metrics overloaded with some trainer_params,
  // which tbh, i'm not sure we want to expose, and isn't documented in the types.
  const labeledData = params?.num_labels || params?.train_data_size;

  return (
    <Anchor href="/projects/[projectId]" as={`/projects/${project.id}`} className="my-16 mx-auto block w-full max-w-4xl">
      <div className="transition-default flex cursor-pointer flex-col justify-between rounded border border-gray-300 bg-white py-40 px-32 text-gray-600 shadow-a hover:border-gray-400 ">
        <Debug vars={{ project, params, num_labels: params?.num_labels, train_data_size: params?.train_data_size }} />
        <div className="relative">
          <div className="mb-32 flex items-center justify-between">
            <div className="truncate break-words text-20-24 font-extrabold text-gray-900">{project.name}</div>
            <div className="flex-0 relative z-0 flex items-center justify-end overflow-hidden p-1 ">
              {project.users.slice(0, 5).map((user, i) => (
                <Avatar
                  key={user.id}
                  className={`-mr-4 inline-block text-white ring-1 ring-white ${["z-10", "z-20", "z-30", "z-40", "z-50"][i]}`}
                  user={user}
                  size="xs"
                />
              ))}
              <div className="ml-16 p-1 text-right ">
                <Dropdown overlay={menu} trigger={["click"]} placement="bottomLeft">
                  <IconButton Icon={DotsVerticalIcon} size={32} />
                </Dropdown>
              </div>
            </div>
          </div>

          <div className="mb-32 grid w-full text-gray-600" style={{ gridTemplateColumns: "0.25fr 0.75fr" }}>
            <div>
              <StatDisplay title="Tasks" value={project.completed_tasks_count} outOf={project.tasks_count} />
            </div>
            <div className="grid grid-cols-3 border-gray-200 px-32 sm:border-l-2 ">
              <div>
                <StatDisplay title="Accuracy" value={accuracy} isPercentage />
              </div>
              <div className="hidden sm:block">
                <StatDisplay title="Data labeled" value={labeledData} />
              </div>

              <div className="hidden sm:block">
                <StatDisplay title="Predictions" value={project.pred_count} />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex flex-1 flex-col">
            <div className="font-md mr-20 mb-4 flex items-center text-sm capitalize text-gray-500">
              {project.outputs.map((output) => {
                const typeName = output.task_type?.replaceAll("_", " ");
                switch (output.task_type) {
                  case TaskTypes.classification:
                  case TaskTypes.multilabel:
                    return (
                      <React.Fragment key={output.id}>
                        <Category className="mr-12 h-20 w-20" />
                        {typeName}
                      </React.Fragment>
                    );
                  case TaskTypes.span_tagging:
                    return (
                      <React.Fragment key={output.id}>
                        <SpanTagging className="mr-12 h-20 w-20" />
                        {typeName}
                      </React.Fragment>
                    );
                  case TaskTypes.ordinal_regression:
                    return <React.Fragment key={output.id}>{typeName}</React.Fragment>;
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </Anchor>
  );
};

export const SkeletonProjectCard = () => {
  return (
    <div className="my-16 flex h-full w-full max-w-4xl cursor-pointer flex-col justify-between overflow-hidden rounded border border-gray-100 bg-white text-gray-400 shadow-a">
      <div className="relative py-40 px-32 pb-52 ">
        <div className=" flex animate-pulse items-start">
          <div className="w-0 flex-1 ">
            <Skeleton paragraph={{ rows: 4 }} />
          </div>
          <div className="font-md mr-20 text-sm leading-8">
            <Skeleton avatar paragraph={{ rows: 0 }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
