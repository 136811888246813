import { InfoTooltip } from "@components/molecules/InfoTooltip";
import { SmallDownTriangle, SmallUpTriangle } from "@library/Icons";
import { classNames } from "@library/utils/classNames";
import { PLACEHOLDER_DASHES } from "lib/constants";
import { formatNumberSI } from "lib/utils";
import React from "react";

export type ValueStatus = "default" | "stale" | "updating";

interface StatDisplayProps {
  title: string | React.ReactNode;
  tooltip?: string;
  value: number;
  outOf?: number;
  precision?: number;
  isPercentage?: boolean;
  color?: string;
  className?: string;
  status?: ValueStatus;
}
export const StatDisplay = ({
  title,
  tooltip,
  value,
  className,
  outOf,
  isPercentage,
  precision = 3,
  color,
  status = "default",
  ...props
}: StatDisplayProps) => {
  return (
    <dl className={`${className}`}>
      <dt className="mb-8 text-14-16 text-gray-600">{title}</dt>
      {tooltip && <InfoTooltip text={tooltip} />}
      <dd className="flex items-center font-display text-32-32 text-gray-900">
        {color ? <div className="mr-8 h-12 w-12 rounded" style={{ backgroundColor: color }} /> : null}
        <ValueDisplay value={value} precision={precision} isPercentage={isPercentage} status={status} />
        {outOf !== undefined && <span className="text-gray-600">/{outOf}</span>}
      </dd>
    </dl>
  );
};

interface ValueDisplayProps {
  value: number;
  status: ValueStatus;
  isPercentage?: boolean;
  precision?: number;
}

export const ValueDisplay = ({ value, status = "default", isPercentage, precision = 3 }: ValueDisplayProps) => {
  return (
    <>
      {value === null || value === undefined ? (
        <span className="opacity-20">{PLACEHOLDER_DASHES}</span>
      ) : (
        <span
          className={classNames(
            status === "stale" && "text-gray-500",
            status === "updating" && "animate-pulse bg-gradient-to-r from-gray-700 to-gray-400 bg-clip-text text-transparent"
          )}
        >
          {isPercentage ? formatPercentage(value, precision) : formatNumber(value)}
        </span>
      )}
    </>
  );
};

export const calcRelativeDelta = (current: number | null, previous: number | null) => {
  if (previous === null || current === null) {
    return null;
  }
  if (current === 0 && previous === 0) {
    return 0;
  }
  if (previous === 0) {
    return Infinity;
  }
  const res = (current - previous) / previous;
  return res;
};

interface DeltaDisplayProps {
  relativeDelta: number | null | undefined;
  status?: ValueStatus;
  arrowOnly?: boolean;
}

export const DeltaDisplay = ({ relativeDelta, status = "default", arrowOnly }: DeltaDisplayProps) => {
  if (relativeDelta === null || relativeDelta === undefined || status === "updating") {
    return null;
  }

  if (relativeDelta > 0 || relativeDelta === Infinity) {
    return (
      <span className={classNames("inline-flex flex-nowrap items-center text-11-14 text-green-700", status === "stale" && "opacity-50")}>
        <SmallUpTriangle className="mb-2 inline-block" />

        {!arrowOnly && <span className="ml-[3px]">{formatPercentage(relativeDelta)}</span>}
      </span>
    );
  } else if (relativeDelta < 0) {
    return (
      <span className={classNames("inline-flex flex-nowrap items-center text-11-14 text-red-700", status === "stale" && "opacity-50")}>
        {/* Using color and arrow to signify negative only */}
        <SmallDownTriangle className="inline-block" />
        {!arrowOnly && <span className="ml-[3px]">{formatPercentage(relativeDelta * -1)}</span>}
      </span>
    );
  } else {
    return (
      <span className={classNames("inline-flex flex-nowrap items-center text-11-14 text-gray-700", status === "stale" && "opacity-50")}>
        {/* Neutral – we don't show an up arrow as that gives the impression of increase */}
        {!arrowOnly && (
          <>
            <SmallUpTriangle className="mb-2 inline-block" />
            <span className="ml-[3px]">{formatPercentage(relativeDelta)}</span>
          </>
        )}
      </span>
    );
  }
};

export const formatPercentage = (value: number, precision: number = 2): string | JSX.Element => {
  if (value === undefined || value === null || isNaN(value)) {
    return <span className="opacity-20">{PLACEHOLDER_DASHES}</span>;
  }
  if (0 < value && value < 0.001) {
    return `<0.1%`;
  }
  if (value === Infinity) {
    return <span className="text-[150%]">∞</span>;
  }
  if (value > 1) {
    return `${+value.toPrecision(precision)}x`;
  }
  // The + operator is used to convert the number to a string to prevent it going to scientific notation
  return +(value * 100).toPrecision(precision) + "%";
};

export const formatNumber = (value?: number): string | JSX.Element => {
  if (value === undefined) {
    return <span className="opacity-20">{PLACEHOLDER_DASHES}</span>;
  }
  return formatNumberSI(value);
};
