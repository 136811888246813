import { SearchIcon } from "@heroicons/react/solid";
import React from "react";

interface SearchProps {
  placeholder: string;
  onChange: (value: string) => void;
}

interface PageHeaderProps {
  title: string;
  actions?: React.ReactNode;
  searchProps?: SearchProps;
}

const PageHeader = ({ title, actions, searchProps }: PageHeaderProps): JSX.Element => {
  return (
    <header className="border-b border-gray-300">
      <div className="mx-auto px-24 lg:px-32 ">
        <div className={`relative top-1 border-b border-gray-500 py-24 pb-24`}>
          <div className="flex items-center justify-between">
            <div className="flex min-w-0 flex-1 items-center">
              <h2 className="hidden min-w-[200px] truncate font-display text-32-32 font-bold text-gray-900 sm:inline-block">{title}</h2>
              <div className="w-[384px]">
                {searchProps && (
                  <div className="group relative -my-20 mr-20 flex items-center rounded text-gray-600 outline-none focus:outline-none">
                    <SearchIcon className="absolute left-12 h-28 w-28 opacity-60 group-focus-within:opacity-100" />
                    <input
                      className="text-md w-full rounded p-12 pl-52 text-16-24 text-gray-600 placeholder-gray-500 focus:bg-gray-200 focus:text-gray-900 focus:outline-none"
                      placeholder={searchProps.placeholder}
                      onChange={(event) => searchProps.onChange(event.target.value)}
                    />
                  </div>
                )}
              </div>
            </div>
            {actions && <div className="flex">{actions}</div>}
          </div>
        </div>
      </div>
    </header>
  );
};

export default PageHeader;
