import Head from "next/head";
import Script from "next/script";
import React from "react";

interface Props {
  pageName: string;
  siteTitle?: string;
  title?: string;
  description?: string;
  image?: string;
}

function CustomHead(props: Props): JSX.Element {
  const siteTitle = props.siteTitle || "Humanloop";
  // pageName is the name of the page e.g. 'Settings'
  // siteTitle is name of the site
  // title is the override for this page.
  const DEFAULT_PAGE_TITLE = `${props.pageName} / ${siteTitle}`;
  const DEFAULT_DESCRIPTION = "Humanloop – Improve GPT-3 from human feedback";
  const title = props.title ?? DEFAULT_PAGE_TITLE;
  const description = props.description ?? DEFAULT_DESCRIPTION;
  const img = props.image ?? "https://humanloop.com/assets/marketing/social/social-gradient.png";
  return (
    <>
      <Script src="//cdn.headwayapp.co/widget.js" strategy="lazyOnload" />

      <Head>
        {/* Top one is preferred as its an SVG that responds to system dark mode settings */}
        <link rel="icon" href="/img/logos/SVG/symbol.svg" />
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />

        <link rel="manifest" href="/favicon/site.webmanifest" />
        <title>{title}</title>
        <meta name="og:title" content={title} />

        <meta name="title" content={title} />
        <meta name="description" content={description} />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={img} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={img}></meta>
      </Head>
    </>
  );
}

export default CustomHead;
