import React from "react";

const Category = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M22.3 7.50001L19.9 1.64101C19.6588 1.09814 19.12 0.748743 18.526 0.750006H5.475C4.88062 0.748346 4.34132 1.09781 4.1 1.64101L1.7 7.50001M12 6.75001V0.750006M14.25 18.75H18.75M3.3 23.25H20.7C21.6941 23.25 22.5 22.4441 22.5 21.45V8.55001C22.5 7.55589 21.6941 6.75001 20.7 6.75001H3.3C2.30589 6.75001 1.5 7.55589 1.5 8.55001V21.45C1.5 22.4441 2.30589 23.25 3.3 23.25Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Category;
